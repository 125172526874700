import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import * as api from "./api";
import { keyBy } from "lodash";
import LoginStore from "../session/LoginStore";
import { ContentProvider, FeatureProviders } from "./ContentProvider";
import { ProviderType } from "./ProviderType";
import SessionStore from "../session/SessionStore";
import * as FAC from "../../modules/app/site/featureAccessControl";
import {
  appleListingManagement,
  oloIntegrationAllowed,
  showAppleOnSocial,
} from "../../modules/app/site/featureAccessControl";
import { titleCase } from "../../util/generalUtils/titleCase";

export class ProvidersStore {
  private SocialProviders = ["FACEBOOK", "TWITTER", "INSTAGRAM", "GOOGLE", "LINKEDIN", "APPLE"] as const;
  private SocialIngestionProviders = ["FACEBOOK", "INSTAGRAM", "GOOGLE", "LINKEDIN"] as const;

  static PageInsightsProviders = ["GOOGLE", "YELP"] as const;

  /** hacky thing that can be used to create a providers store that is restricted to a specific provider type
   * @deprecated - please don't rely on this, causing spaghetti in this global store*/
  type: ProviderTypeName | null = null;
  providers: ContentProvider[] = [];
  adviceLocalProviders: ContentProvider[] = [];

  /* Feature Providers
   * A concept introduced to only show providers that Chatmeter manages. This makes the listings reports more accurate.
   * - "All" Providers are all the listings providers.
   * - "Managed" Providers are only the ones that Chatmeter actively manages.
   * - "Monitored" are all the remaining listing providers.
   */

  featureProviders: FeatureProviders = {
    all: [],
    managed: [],
    monitored: [],
  };

  loaded = false;

  getProvider(id: string): ContentProvider | undefined {
    return this.providers.find((p) => p.id === id);
  }

  displayNameFor(id: string): string {
    const found = this.providers.find(
      (x) => x.id === id.toUpperCase() || (id.toUpperCase() === "GOOGLEMAP" && x.id === "GOOGLE")
    );
    if (!found) {
      return titleCase(id);
    } else {
      return found.name;
    }
  }

  constructor() {
    makeObservable(this, {
      providers: observable,
      adviceLocalProviders: observable,
      loaded: observable,
      socialProviders: computed,
      reviewProviders: computed,
      reviewBuilderRevGenProviders: computed,
      pulseProviders: computed,
      reviewResponseProviders: computed,
      listingsProviders: computed,
      webRankProviders: computed,
      localRankProviders: computed,
      mediaProviders: computed,
      pageInsightsProviders: computed,
      _validPageInsightsProviders: observable,
      validPageInsightsProviders: computed,
      setValidPageInsightsProviders: action,
      pullListingsOnlyProviders: computed,
      byId: computed,
    });

    reaction(
      () => LoginStore.isLoggedIn,
      (token) => {
        if (token) {
          this._getProviders();
        } else {
          runInAction(() => {
            this.loaded = false;
            this.providers.splice(0);
          });
        }
      },
      {
        fireImmediately: true,
      }
    );

    reaction(
      () => SessionStore.account,
      () => this.getFeatureProviders(SessionStore.account?.accountId)
    );

    reaction(
      () => this.loaded && this.providers.length > 0,
      () => this.modifyProviderDisplayNames()
    );
  }

  _getProviders = () => {
    const providersPromise = api.getProviders(true);
    if (this.adviceLocalProviders.length === 0)
      api.getAdviceLocalProviders().then((p) => (this.adviceLocalProviders = p));
    return providersPromise.then(
      action("onProvidersLoaded", (providers) => {
        this.providers.splice(0);
        this.providers.push(...providers);
        this.loaded = true;
      })
    );
  };

  getFeatureProviders = async (accountId?: string) => {
    if (!accountId) return;
    const managedMonitoredTabsAllowed = FAC.hasListingsToggleAndLlmEnabled();
    if (managedMonitoredTabsAllowed) {
      const managedProviders = await api.getFeatureProviders("managed", accountId);
      const monitoredProviders = await api.getFeatureProviders("monitored", accountId);
      this.featureProviders.managed.push(...managedProviders);
      this.featureProviders.monitored.push(...monitoredProviders);
    }
  };

  modifyProviderDisplayNames = async () => {
    const providersWithAltNames = this.providers.map((p) => {
      const maybeAltName = this.alternateProviderDisplayNames(p.id);
      return maybeAltName ? { ...p, name: maybeAltName } : p;
    });
    this.providers = [...providersWithAltNames];
  };

  get socialProviders(): ContentProvider[] {
    const set = new Set<string>(this.SocialProviders);
    if (!appleListingManagement() && !showAppleOnSocial()) {
      set.delete("APPLE");
    }
    return this.providers.filter((p) => set.has(p.id));
  }

  get socialIngestionProviders(): ContentProvider[] {
    const set = new Set<string>(this.SocialIngestionProviders);
    return this.socialProviders.filter((p) => set.has(p.id));
  }

  get reviewProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.dashboardSettings.reviews);
  }

  get reviewBuilderRevGenProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.providerSettings.reviewBuilderAllowed);
  }

  get pulseProviders(): ContentProvider[] {
    return this.providers.filter((p) => {
      return p.dashboardSettings.reviews && !p.dashboardSettings.hideReviewSentiment;
    });
  }

  get reviewResponseProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.providerSettings.canRespondToReviews);
  }

  get listingsProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.dashboardSettings.listings);
  }

  get webRankProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.dashboardSettings.webRanking);
  }

  get localRankProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.dashboardSettings.localRanking);
  }

  get mediaProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.dashboardSettings.media);
  }

  get oauthProviders(): ContentProvider[] {
    return this.providers.filter((p) => p.providerSettings.showInThirdPartyCredentials);
  }

  get pageInsightsProviders(): ContentProvider[] {
    const set = new Set<string>(ProvidersStore.PageInsightsProviders);
    return this.providers.filter((p) => set.has(p.id));
  }

  _validPageInsightsProviders: ContentProvider[] = [];

  get validPageInsightsProviders(): ContentProvider[] {
    return this._validPageInsightsProviders;
  }

  setValidPageInsightsProviders(): Promise<void> {
    return api.getSearchAnalyticsProviders().then((data) => {
      const providerIds = new Set(data.providers.map((x) => x.toUpperCase()));
      const filteredProviders = this.providers.filter((p) => providerIds.has(p.id));
      this._validPageInsightsProviders = [...filteredProviders];
    });
  }

  get pullListingsOnlyProviders(): ContentProvider[] {
    let set;
    set = this.providers.filter((p) => p.dashboardSettings.pullListingsOnly);
    if (!oloIntegrationAllowed()) {
      set = set.filter((p) => p.id !== "OLO");
    }
    return set;
  }

  get byId(): Record<string, ContentProvider> {
    const idx: Record<string, ContentProvider> = {};
    for (let p of this.providers) {
      idx[p.id] = p;
    }
    return idx;
  }

  // Used to display a different name for a provider based on a feature flag
  alternateProviderDisplayNames(id: string): string | undefined {
    switch (id) {
      case "REVIEWBUILDER":
        return FAC.surveysAllowed() ? "Surveys" : "Review Builder";
      default:
        return undefined;
    }
  }

  validProviders(groupId: string, type: ProviderTypeName = ProviderType.REVIEWS): Promise<ContentProvider[]> {
    return api.getProvidersByGroupAndType(groupId, type);
  }

  //To only return valid providers as a promise instead of data for listing accuracy
  validProvidersData(groupId: string, type: ProviderTypeName = ProviderType.REVIEWS): Promise<ContentProvider[]> {
    return api.getProvidersByGroupAndType(groupId, type);
  }

  // TODO: Need to fix this: it's fetching only the selected type, which can potentially mess
  // with other components that need a different type. It also has me-oh-my bugs and weird types
  /**
   * @deprecated - this probably isn't what you want. See above comments
   */
  validProvidersAndKeyedById(
    groupId: string,
    type: ProviderTypeName = ProviderType.REVIEWS
  ): Promise<ContentProvider[] | string> {
    if (this.providers.length && this.type === type) {
      return Promise.resolve(this.providers);
    } else {
      return this.validProviders(groupId, type)
        .then((providers) => ({ providers, providersById: keyBy(providers, "id") }))
        .then(() => (this.type = type));
    }
  }
}

export type PageInsightsProviders = (typeof ProvidersStore.PageInsightsProviders)[number];
export type ProviderTypeName = ProviderType;
export default new ProvidersStore();
